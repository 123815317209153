<template>
    <router-view/>
</template>

<script>
    export default {}
</script>
<style>

#nprogress .bar {
  background: var(--color-primary) !important; 
}

#nprogress .peg {
  box-shadow: 0 0 10px var(--color-primary), 0 0 5px var(--color-primary) !important;
}

</style>
