<template>
    <div class="header-default">
    <div style="width: 1200px;margin: 0 auto;display: flex;">
        <div class="header-logo" >
            <router-link to="/" style="text-decoration: none;color: #ffffff;">
                <img src="@/assets/images/layout/logo-red.png" style="width: 40px;height: 40px;"/>
                <span style="padding: 10px;">DEVELOPERS</span>
            </router-link>
        </div>
        <div class="header-nav mainmenu-nav">
            <ul class="mainmenu">
                <li><a :class="{'active':isSdkPage(mobileSDKPage)}" style="cursor: pointer;" @click="toHeadPage('/mobileSDK')">Mobile SDK</a></li>
                <li><a :class="{'active':isSdkPage(payloadSDKPage)}" style="cursor: pointer;" @click="toHeadPage('/payloadSDK')">Payload SDK</a></li>
                <li><a :class="{'active':isSdkPage(cloudAPIPage)}" style="cursor: pointer;" @click="toHeadPage('/cloudAPI')">上云API</a></li>
                <!-- <li><a :class="{'active':isSdkPage(zskPage)}" style="cursor: pointer;" @click="toHeadPage('/forumList/zsk')">知识库</a></li> -->
                <li><a :class="{'active':isSdkPage(tbPage)}" style="cursor: pointer;" @click="toHeadPage('/forumList/tb')">技术论坛</a></li>    
                <li v-if="isManagement"><a :class="{'active':isAdminPage}" style="cursor: pointer;" @click="toAdminPage">后台管理</a></li>    
            </ul>
        </div>
        <div class="header-avatar mainmenu-nav" >
            <ul class="mainmenu">
                <li v-if="token==null || token==''">
                    <router-link to="/login" style="padding: 0px !important;">
                        <i class="el-icon-user-solid"></i>
                        &nbsp;登录
                    </router-link>
                </li>
                <li v-else class="has-droupdown has-menu-child-item">
                    <a href="#" style="padding: 0px !important;">
                        <i class="el-icon-user-solid"></i>
						&nbsp;{{nickName}}
                    </a>
                    <ul class="submenu">
                        <li><router-link to="/personalCenter">个人中心</router-link></li>
                        <li><a @click="toMyBar" style="cursor: pointer;">我的贴吧</a></li>
						<li><router-link to="/my-app/index">我的应用</router-link></li>
						<li><router-link to="/my-act/myposts">我的活动</router-link></li>
                        <li ><a  href="#" @click="logoutUser">登出</a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    </div>

</template>
<script>
import { getToken, getPersonCode, getNickName } from '@/utils/auth'
export default {
    name: "Header",
    data() {
        return {
            token: getToken(),
            isManagement: false,
            mobileSDKPage: ['MobileSDK','MobileSDKDocView','MobileSDKApiView','MobileSDKDownload'],
            payloadSDKPage: ['PayloadSDK','PayloadSDKDocView','PayloadSDKApiView','PayloadSDKDownload'],
            cloudAPIPage: ['CloudAPI','CloudAPIDocView','CloudAPIDownload'],
            zskPage: ['/forumList/zsk','ZskGroup','ZskView'],
            tbPage: ['/forumList/tb','Userbar','TbGroup','TbView','TbNew','/forumList/zsk','ZskGroup','ZskView'],
			nickName: ''
        }
    },
	mounted(){
	    this.nickName = getNickName();
	},
    computed:{
        isSdkPage(){
            return function(list){
                const currentPage = this.$route.name;
                const currentPath = this.$route.path;
                return list.find(item => item == currentPage || item == currentPath)
            }
        },
        isAdminPage(){
            const currentPage = this.$route.name
            const adminPage = ['AdminTbTheme','AdminTbList','AdminTbUser','AdminLibraryList','AdminFeedback','AdminFeedbackDetail','AdminUser','AdminUserDetail','Dict','DictData']
            return adminPage.find(item => item == currentPage)
        }
    },
    watch: {
        $route: {
            handler: function(route) {
                let roles = localStorage.getItem('USER_ROLES')
                if(typeof roles!='undefined' && roles!= '' && roles!= null && (roles.indexOf('KNOWLEDGE')!=-1 || roles.indexOf('TIEBAR')!=-1 || roles.indexOf('FEEDBACK')!=-1|| roles.indexOf('ADMIN')!=-1|| roles.indexOf('PLATFORM')!=-1)){
                    this.isManagement = true
                }else{
                    this.isManagement = false
                }
            },
            immediate: true
        },
    },
    methods:{
       async toAdminPage(){
            // 获取用户动态权限列表-管理端页面
            let addRoutes = this.$store.state.permission.addRoutes
            // 用户已登录，但不存在动态路由，说明页面存在刷新
            if(addRoutes.length <= 1){
                // 当前页面，直接刷新
                this.$router.go(0)
            }else{
                let first = addRoutes[0]
                if(first.path==this.$route.path){
                    // 当前页面，直接刷新
                    this.$router.go(0)
                }else{
                    // 页面跳转
                    this.$router.push(first.path)
                }
                
            }
        },
        async  logoutUser(){
            // 登出
            await this.$store.dispatch('user/logout')
            this.token = getToken() 

            // 页面跳转
            this.$router.go(0)
        },
        toHeadPage(path,nav){
            if(this.$route.path==path){
                this.$router.go(0)
            }else{
                this.$router.push(path)
            }
        },
        toMyBar () {
            if(this.$route.path=='/userbar'){
                let currentPersonCode = getPersonCode()
                let userPersonCode = this.$route.query.personCode
                if(currentPersonCode==userPersonCode){
                    this.$router.go(0)
                }else{
                    this.$router.replace({ name: 'Userbar', query: { personCode:  getPersonCode()} })
                }
                
            }else{
                this.$router.push({ name: 'Userbar', query: { personCode: getPersonCode() } })
            }
        },
    }
}
</script>
<style scoped type="scss">
.header-default{
    z-index: 2000 !important;
    min-width: 1200px;
}
.header-default .header-logo {
    flex: 0.5;
}
.header-default .header-logo img {
    margin-left: 0px;
}
.header-default .header-nav {
    flex: 2;
    justify-content: flex-end;
}

.mainmenu-nav .mainmenu li.has-droupdown .submenu {
    min-width: 120px;
    text-align: center;
}

.mainmenu-nav .mainmenu > li > a {
    padding: 0 44px 0 10px !important;
}

.active{
    color: var(--color-primary);
}

::v-deep .mainmenu-nav .mainmenu li.has-droupdown .submenu li a, ul li a, ol li a {
    transition: 0s !important;
}
</style>