<template>
    <div class="page-wrapper">
        <AdminHeader/>
        <div class="main-content">
            <el-container class="admin-container">
                <el-aside class="container-side">
                    <router-link :to="item.path" v-for="(item,index) in routerList" :key="index">
                        <div :class="{'side-active':(item.path==$route.path || isChildNav(item.name))}">{{ item.meta.title }}</div>
                    </router-link>
                </el-aside>
                
                <el-container class="container-main">
                    <slot></slot>
                </el-container>
            </el-container>
        </div>

        <Footer />
    </div>

</template>

<script>
import AdminHeader from '@/components/common/AdminHeader'
import Footer from '@/components/common/Footer'

export default {
    components: { AdminHeader, Footer},
    data() {
        return {
            activeNum: 0,
            routerList: []
        };
    },
    computed:{
        isChildNav(){
            return function(name){
                const currentPage = this.$route.name
                const childPageList = {
                    AdminTbUser : ['AdminUserInfo'],
                    AdminUser : ['AdminUserDetail'],
					AdminFeedback : ['AdminFeedbackDetail'],
					AdminLibraryList : ['AdminLibraryArticleCreate','AdminLibraryGroupList'],
                    Dict : ['DictData'],
                }
                const pageList = childPageList[name]
                if(typeof pageList!='undefined'){
                    return pageList.find(item => item == currentPage)
                }else{
                    return false
                }
                
               
            }
           
        }
    },
    mounted(){
        // 获取用户动态权限列表-管理端页面
        let addRoutes = this.$store.state.permission.addRoutes
        if(addRoutes!=null && addRoutes.length>0){
            this.routerList = addRoutes.filter(router => router.path!='*')
        }
    },
    methods: {
        formatter(row, column) {
            return row.title;
        },
        getContent() {
            console.log(this.$refs.myRichText.content);
        }
    }
};
</script>

<style scoped lang="scss">
.page-wrapper{
    background-color: #F5F5F5;
    min-width: 1200px;
}
.main-content{
    padding-top: 66px;
    background-color: #fff;
    min-height: 700px;
    min-width: 1200px;
}

.side-active{
    background-color: var(--color-primary);
    color: #FFFFFF;
    border-radius: 4px;
}
.admin-container{
    min-height: 500px;
    min-width: 1200px;

    .container-side {
        width: 250px;
        padding: 20px 30px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;

        div{
            margin: 10px 0;
            padding: 10px;
            padding-left: 33px;
        }
        a {
            color: #666666;
            text-decoration: none;
        }

    }

    .container-main{
        padding-right: 20px;
    }

}
.el-aside{
    width: 240px !important;
}

</style>