import CryptoJS from 'crypto-js'

export default {
	//defaultKeyStr: 'abcdsxyzhkj12345',
	defaultKeyStr: 'gdu.@piggdu.2022',

	//随机生成指定数量的16进制key
	generatekey (num) {
		let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let key = "";
		for (var i = 0; i < num; i++) {
			let randomPoz = Math.floor(Math.random() * library.length);
			key += library.substring(randomPoz, randomPoz + 1);
		}
		return key;
	},

	//加密
	encrypt (word, keyStr) {
		keyStr = keyStr ? keyStr : this.defaultKeyStr; //判断是否存在key，不存在就用定义好的key
		var key = CryptoJS.enc.Latin1.parse(keyStr);
		var srcs = CryptoJS.enc.Utf8.parse(word);
		var iv = key;
		var encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CFB, padding: CryptoJS.pad.NoPadding });
		return encrypted.toString();
	},
	
	//解密
	decrypt (word, keyStr) {
		keyStr = keyStr ? keyStr : this.defaultKeyStr;
		var key = CryptoJS.enc.Latin1.parse(keyStr);
		var iv = key;
		var decrypt = CryptoJS.AES.decrypt(word, key, {  iv: iv, mode: CryptoJS.mode.CFB, padding: CryptoJS.pad.NoPadding });
		return CryptoJS.enc.Utf8.stringify(decrypt).toString();
	}
}
