import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken, getRefreshToken } from '@/utils/auth'
import router from "../router"

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    // if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = getToken()
    // }
    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
     // 返回结果
     const res = response.data

     // 数据判断，返回文件流数据不解析
     let responseType = response.headers['content-type']
     let whiteList = ['image/png', 'image/jpeg', 'image/jpg', 'application/vnd.ms-excel']
     const isSkip = whiteList.indexOf(responseType) === -1
     if(!isSkip){
       return res
     }

     // if the custom code is not 20000, it is judged as an error.
     if (res.code !== '200') {

      // 10001: 未授权登录; 10002: 访问凭证无效;
      if (res.code === '10001' || res.code === '10002') {
        // // to re-login
        // MessageBox.confirm('授权登陆已过期，请重新登陆', '退出', {
        //   confirmButtonText: '重新登陆',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/refreshToken').then(() => {
        //     location.reload()
        //   })
        // })
        router.push({ name: 'Login', query: { redirect: router.currentRoute.path,...router.currentRoute.query}})

      }
      // 10003: 访问凭证过期; 自动刷新
      if (res.code === '10003') {
        // to re-login
        return refreshToken(response)
      }

      Message({
        message: res.msg || '错误',
        type: 'error',
        duration: 5 * 1000
      })
      //return Promise.reject(new Error(res.message || '错误'))
	  return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
async function  refreshToken(response){
  return await store.dispatch('user/refreshToken', getRefreshToken())
  .then(async () => {
    let { config } = response
    config.headers['X-Token'] = getToken()
    let rs = await service(config).then(res =>{
      return res
    });
    return rs;
  })
}

export default service
