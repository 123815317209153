<template>
    <div class="page-wrapper">
        <Header/>
        <div class="main-content">
            <slot></slot>
        </div>

        <Footer />
    </div>

</template>

<script>

    import Header from '@/components/common/Header'
    import Footer from '@/components/common/Footer'

    export default {
        name: 'Layout',
        components: { Header, Footer},
        props: {
        }
    }

</script>

<style scoped>
    .page-wrapper{
        background-color: #F5F5F5;
        min-width: 1200px;
    }
    .main-content{
        padding-top: 66px;
        background-color: #fff;
        min-height: 700px;
        min-width: 1200px;
    }
</style>