import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CoolLightBox from 'vue-cool-lightbox'
import VueScrollActive from 'vue-scrollactive'
import AOS from 'aos'
import VueTypedJs from 'vue-typed-js'

import Layout from '@/components/common/Layout'
import Admin from '@/components/common/Admin'

import Cookies from 'js-cookie'

import Element from 'element-ui'

import store from './store'

import './permission' // permission control

import * as filters from './filters' // global filters

import AES from './utils/AES'

import SM from './utils/smUtils'

import RC from './utils/recover'

import mavonEditor from 'mavon-editor'

import 'github-markdown-css'
import 'highlight.js/styles/github.css'

import '@/theme/element/index.css'
import 'aos/dist/aos.css'
import '@/assets/scss/style.scss'
import 'bootstrap/scss/bootstrap.scss'
import 'mavon-editor/dist/css/index.css'

import { BootstrapVue,IconsPlugin} from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import Directives from './utils/directives'
Vue.use(Directives)

Vue.config.productionTip = false
Vue.use(CoolLightBox)
Vue.use(VueScrollActive)
Vue.use(VueTypedJs)
Vue.use(mavonEditor)
Vue.component('Layout',Layout)
Vue.component('Admin',Admin)


Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  // locale: enLang // 如果使用中文，无需设置，请删除
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.prototype.$aes = AES

Vue.prototype.$sm = SM

Vue.prototype.$rc = RC

Vue.prototype.docsUrl = 'https://developer.gdu-tech.com:10083'
Vue.prototype.docsMsdkApiUrl = ''

new Vue({
  router,
  store,
  created () {
    AOS.init({
      duration: 800,
      once: true,
    })
  },
  render: h => h(App)
}).$mount('#app');
