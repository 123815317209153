// 使用SM2
import { sm2 } from 'sm-crypto'

const publicKey = '03cd25180b95e062c952399c880b2c0dea1479d0cb5be0f7c09715e3aade2f1efc'
const privateKey = '00ab6bd58faf64bdd745d34d6b67e39d79e77a6dd90b5a4422ec5c8f2cb38b14b3'
const cipherMode = 1 ;// 1 - C1C3C2，0 - C1C2C3，默认为1

export default {
    //加密
    encryptedData(word) {
        return sm2.doEncrypt(word, publicKey,cipherMode)
    },
    //解密
    decryptedData(word) {
        let str = sm2.doDecrypt(word.substring(2), privateKey, cipherMode);
        return str
    },
}
