import { login, logout, getInfo, userRefreshToken, vcodelogin } from '@/api/user'
import { getToken, setToken, removeToken, getRefreshToken, setRefreshToken, getPersonCode, setPersonCode, getNickName, setNickName } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  refreshToken: getRefreshToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_REFRESH_TOKEN: (state, refreshToken) => {
    state.refreshToken = refreshToken
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    // const { username, password } = userInfo
    return   new Promise((resolve, reject) =>  {
       login({ ...userInfo }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        commit('SET_REFRESH_TOKEN', data.refreshToken)
        setToken(data.token)
        setRefreshToken(data.refreshToken)

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  vcodelogin({ commit }, userInfo) {
    // const { username, password } = userInfo
    return new Promise((resolve, reject) =>  {
		vcodelogin({ ...userInfo }).then(response => {
			const { data } = response
			commit('SET_TOKEN', data.token)
			commit('SET_REFRESH_TOKEN', data.refreshToken)
			setToken(data.token)
			setRefreshToken(data.refreshToken)

			resolve()
		}).catch(error => {
			reject(error)
		})
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { roles, name, avatar, introduction, personCode, nickName } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }

        localStorage.setItem('USER_ROLES',[...roles])

        commit('SET_ROLES', roles)
        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        commit('SET_INTRODUCTION', introduction)

        //
        setPersonCode(personCode)
        setNickName(nickName)

        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        // dispatch('/', null, { root: true })
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

   // 刷新授权码
   async refreshToken({ commit }) {
    return await new Promise((resolve, reject) => {
      userRefreshToken(state.refreshToken).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        commit('SET_REFRESH_TOKEN', data.refreshToken)
        setToken(data.token)
        setRefreshToken(data.refreshToken)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('/', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
