<template>
    <div class="footer-default">
        <div class="footer-top">
            <div class="container" style="width: 100%;margin: 0 0;max-width: unset!important;">
                <div class="row">
                    <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                        <div class="rn-footer-widget">
                            <h4 class="title">开发</h4>
                            <div class="inner">
                                <ul class="footer-link link-hover">
                                    <li><a style="cursor: pointer;" @click="toHeadPage('/mobileSDK')">Mobile SDK</a></li>
                                    <li><a style="cursor: pointer;" @click="toHeadPage('/payloadSDK')">Payload SDK</a></li>
                                    <li><a style="cursor: pointer;" @click="toHeadPage('/cloudAPI')">上云API</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                        <div class="rn-footer-widget">
                            <div class="widget-menu-top">
                                <h4 class="title">开发交流</h4>
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li>
                                            <a href="https://github.com/GduDeveloper" target="_blank">Github</a>
                                        </li>
                                        <li><a style="cursor: pointer;" @click="toHeadPage('/forumList/zsk')">知识库</a></li>
                                        <li><a style="cursor: pointer;" @click="toHeadPage('/forumList/tb')">技术论坛</a></li>    
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-6 col-12">
                        <div class="rn-footer-widget">
                            <h4 class="title">联系我们</h4>
                            <div class="inner">
                                <ul class="footer-link link-hover">
                                    <li><a style="cursor: pointer;" @click="toHeadPage('/question')">用户反馈</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 offset-lg-4 col-md-6 col-sm-6 col-12" style="display: flex;">
						<div class="rn-footer-widget logo-foot" style="width: 100%;">
							<img src="@/assets/images/layout/logo_foot.png" style="margin-bottom: 3rem;"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-copyright copyright-area copyright-style-one" style="width: 1200px;margin: 0 auto;">
            <div class="container" style="width: 100%;margin: 0 0;max-width: unset!important;">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-10 col-sm-12 col-12">
                        <div class="copyright-left">
                            <ul class="ft-menu link-hover">
                                <li>
                                    <a href="https://www.gdu-tech.com" style="cursor: pointer;" target="_bank" >Copyright © 2022普宙科技有限公司</a>
                                </li>
                                <li>
                                    <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="cursor: pointer;" target="_bank" >粤ICP备15077823</a>
                                </li>
                                <li>
                                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502006252" style="cursor: pointer;" target="_bank" >鄂公网安备 42018502006252号</a>
                                </li>
                                <li>
                                    <router-link to="/policy/index?lx=1" class="router-link-a" style="cursor: pointer;">用户条款</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-2 col-sm-12 col-12">
                        <div class="copyright-right text-center text-lg-end">
                            <p class="copyright-text">© 普宙开发平台 {{ new Date().getFullYear() }} &nbsp;&nbsp;&nbsp;&nbsp;中国
                                <img style="padding-left: 20px;" src="@/assets/images/layout/national_flag.png">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollTop/>
    </div>

</template>
<script>
    import ScrollTop from '@/components/common/ScrollTop'

    export default {
        name: 'Footer',
        components: { ScrollTop },
        props: {
            data: {
                default: null
            }
        },
        methods:{
            toHeadPage(path){
                if(this.$route.path==path){
                    this.$router.go(0)
                }else{
                    this.$router.push(path)
                }
            },
        }
    }
</script>
<style scoped>
    .footer-default{
        background-image: linear-gradient(0deg, #FFFFFF, #F5F5F5);
        min-width: 1200px;
    }
    .logo-foot{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .copyright-right{
        height: 30px;
    }
    .copyright-text{
        line-height: 30px;
        height: 30px;
    }

    .footer-link li a:hover{
        text-decoration:underline;
        text-decoration-color:#6E6E6E;
    }

    .ft-menu li a:hover{
        text-decoration:underline;
        text-decoration-color:#6E6E6E;
    }

    .router-link-a:hover{
        text-decoration:underline;
        text-decoration-color:#6E6E6E;
    }
</style>