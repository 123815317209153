export default {
	recover (target, source) {
		if (target === undefined || target === null) { throw new TypeError('Cannot convert first argument to object') }
		var to = Object(target)
		if (source === undefined || source === null) { return to }
		var keysArray = Object.keys(Object(target))
		for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
			var nextKey = keysArray[nextIndex]
			var desc = Object.getOwnPropertyDescriptor(target, nextKey)
			if (desc !== undefined && desc.enumerable) {
				if (to.hasOwnProperty(nextKey)) {
					if (to[nextKey] instanceof Array) {
						to[nextKey] = source[nextKey]
					} else if (to[nextKey] instanceof Object) {
						recover(to[nextKey], source[nextKey])
					} else if (source[nextKey] !== undefined) {
						to[nextKey] = source[nextKey]
					} else if (typeof (to[nextKey]) === 'string') {
						to[nextKey] = ''
					} else {
						to[nextKey] = undefined
					}
				}
			}
		}
		return to
	},
	recoverNotNull (target, source) {
		if (target === undefined || target === null) { throw new TypeError('Cannot convert first argument to object') }
		var to = Object(target)
		if (source === undefined || source === null) { return to }
		var keysArray = Object.keys(Object(target))
		for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
			var nextKey = keysArray[nextIndex]
			var desc = Object.getOwnPropertyDescriptor(target, nextKey)
			if (desc !== undefined && desc.enumerable) {
				if (to.hasOwnProperty(nextKey)) {
					if (to[nextKey] instanceof Array) {
						to[nextKey] = source[nextKey]
					} else if (to[nextKey] instanceof Object) {
						recover(to[nextKey], source[nextKey])
					} else if (source[nextKey] !== undefined) {
						to[nextKey] = source[nextKey]
					}
				}
			}
		}
		return to
	}
}

/**
 * 表单对象赋值:
 * 对目标对象存在且源对象同样存在的属性，全部覆盖；
 * 目标对象不存在但是源对象存在的属性， 全部丢弃；
 * 目标对象存在但是源对象不存在的属性，如果是字符串赋值为空串，其余类型赋值为undefined
 */
export function recover (target, source) {
	if (target === undefined || target === null) { throw new TypeError('Cannot convert first argument to object') }
	var to = Object(target)
	if (source === undefined || source === null) { return to }
	var keysArray = Object.keys(Object(target))
	for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
		var nextKey = keysArray[nextIndex]
		var desc = Object.getOwnPropertyDescriptor(target, nextKey)
		if (desc !== undefined && desc.enumerable) {
			if (to.hasOwnProperty(nextKey)) {
				if (to[nextKey] instanceof Array) {
					to[nextKey] = source[nextKey]
				} else if (to[nextKey] instanceof Object) {
					recover(to[nextKey], source[nextKey])
				} else if (source[nextKey] !== undefined) {
					to[nextKey] = source[nextKey]
				} else if (typeof (to[nextKey]) === 'string') {
					to[nextKey] = ''
				} else {
					to[nextKey] = undefined
				}
			}
		}
	}
	return to
};

/**
 * 表单对象赋值:
 * 对目标对象存在且源对象同样存在的属性，全部覆盖；
 * 目标对象不存在但是源对象存在的属性， 全部丢弃；
 * 目标对象存在但是源对象不存在的属性，保留目标对象的属性不做处理
 */
export function recoverNotNull (target, source) {
	if (target === undefined || target === null) { throw new TypeError('Cannot convert first argument to object') }
	var to = Object(target)
	if (source === undefined || source === null) { return to }
	var keysArray = Object.keys(Object(target))
	for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
		var nextKey = keysArray[nextIndex]
		var desc = Object.getOwnPropertyDescriptor(target, nextKey)
		if (desc !== undefined && desc.enumerable) {
			if (to.hasOwnProperty(nextKey)) {
				if (to[nextKey] instanceof Array) {
					to[nextKey] = source[nextKey]
				} else if (to[nextKey] instanceof Object) {
					recover(to[nextKey], source[nextKey])
				} else if (source[nextKey] !== undefined) {
					to[nextKey] = source[nextKey]
				}
			}
		}
	}
	return to
}