import request from '@/utils/request'

export function login (data) {
	return request({
		url: '/authToken/login',
		method: 'post',
		data
	})
}

export function vcodelogin (data) {
	return request({
		url: '/authToken/vcodelogin',
		method: 'post',
		data
	})
}

export function userRefreshToken (reToken) {
	return request({
		url: '/authToken/refreshToken',
		method: 'post',
		params: { refreshToken: reToken }
	})
}

export function getInfo (token) {
	return request({
		url: '/authToken/getUserInfo',
		method: 'post',
		params: { token }
	})
}

export function logout () {
	return request({
		url: '/authToken/logout',
		method: 'post'
	})
}

// 重置用户密码
export function resetUserPassword (id) {
	return request({
		url: `/system/sysUser/resetUserPassword/${id}`,
		method: 'post'
	})
}



// ------ 公共数据查询
// 用户公共数据
export function getPublicUserInfo (code) {
	return request({
		url: `/pt/common/getPublicUserInfo/${code}`,
		method: 'post'
	})
}

//获取当前用户登录信息
export function getUserInfo () {
	return request({
		url: '/system/sysUser/getUserInfo',
		method: 'post'
	})
}

//根据传入id获取用户登录信息
export function getUserInfoById (data) {
	return request({
		url: '/system/sysUser/getUserInfoById',
		method: 'post',
		params: data
	})
}

//保存用户头像
export function saveUserAvatar (data) {
	return request({
		url: '/system/sysUser/saveUserAvatar',
		method: 'post',
		data
	})
}

// 绑定账户邮箱或手机
export function saveEmailOrPhone (data) {
	return request({
		url: '/system/sysUser/saveEmailOrPhone',
		method: 'post',
		params: data
	})
}

// 绑定开发者邮箱或手机
export function saveEmailOrPhoneDeveloper (data) {
	return request({
		url: '/system/sysUser/saveEmailOrPhoneDeveloper',
		method: 'post',
		params: data
	})
}

// 保存个人中心
export function savePerson (data) {
	return request({
		url: '/system/sysUser/savePerson',
		method: 'post',
		params: data
	})
}

// 查询昵称是否存在
export function existsNickName (data) {
	return request({
		url: '/system/sysUser/existsNickName',
		method: 'post',
		params: data
	})
}

// 检测用户类型
export function checkUserType () {
	return request({
		url: '/system/sysUser/checkUserType',
		method: 'post'
	})
}

// 修改登录密码
export function changePasswordSave (data) {
	return request({
		url: '/system/sysUser/changePasswordSave',
		method: 'post',
		params: data
	})
}
