import Cookies from 'js-cookie'

const TokenKey = 'JWT-Token'
const RefreshTokenKey = 'JWT-Refresh-Token'
const PersonCode = 'JWT-PERSON-CODE'
const NickName = 'NICK-NAME'
const UserRoles = 'USER_ROLES'

export function getToken() {
  return localStorage.getItem(TokenKey)
}
export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function getRefreshToken() {
  return localStorage.getItem(RefreshTokenKey)
}
export function setRefreshToken(refreshToken) {
  return localStorage.setItem(RefreshTokenKey, refreshToken)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey) & localStorage.removeItem(RefreshTokenKey) & localStorage.removeItem(PersonCode) & localStorage.removeItem(NickName) & localStorage.removeItem(UserRoles)
}

export function getPersonCode() {
  return localStorage.getItem(PersonCode)
}
export function setPersonCode(personCode) {
  return localStorage.setItem(PersonCode, personCode)
}

export function getNickName() {
  return localStorage.getItem(NickName)
}
export function setNickName(nickName) {
  return localStorage.setItem(NickName, nickName)
}

