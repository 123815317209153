import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const constantRoutes =  [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Index'),
        meta: {
            title: '首页',
        },
    },
    {
        path: '/index',
        name: 'Index',
        component: () => import('@/views/Index'),
        meta: {
            title: '首页',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login'),
        meta: {
            title: '用户登录',
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Register'),
        meta: {
            title: '用户注册',
        },
    },
    {
        path: '/personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/user/PersonalCenter'),
        meta: {
            requiresAuth: true,
            title: '个人中心',
        },
    },
    {
        path: '/personalCenterEdit',
        name: 'PersonalCenterEdit',
        component: () => import('@/views/user/PersonalCenterEdit'),
        meta: {
            title: '编辑资料',
        },
    },
    {
        path: '/forgetPwd1',
        name: 'ForgetPwd1',
        component: () => import('@/views/ForgetPwd1'),
        meta: {
            title: '忘记密码',
        },
    },
	{
	    path: '/forgetPwd2',
	    name: 'ForgetPwd2',
	    component: () => import('@/views/ForgetPwd2'),
	    meta: {
	        title: '重置密码-填写验证码',
	    },
	},
    {
        path: '/forgetPwd3',
        name: 'ForgetPwd3',
        component: () => import('@/views/ForgetPwd3'),
        meta: {
            title: '重置密码',
        },
    },
    {
        path: '/SuccessTip',
        name: 'SuccessTip',
        component: () => import('@/views/SuccessTip'),
        meta: {
            title: '操作成功',
        },
    },
	{
	    path: '/resetPwdSuccessTip',
	    name: 'ResetPwdSuccessTip',
	    component: () => import('@/views/ResetPwdSuccessTip'),
	    meta: {
	        title: '操作成功',
	    },
	},
    {
        path: '/mobileSDK',
        name: 'MobileSDK',
        component: () => import('@/views/sdk/mobile/Overview'),
        meta: {
            title: 'Mobile SDK',
        },
    },
    {
        path: '/mobileSDK/docsview',
        name: 'MobileSDKDocView',
        component: () => import('@/views/sdk/mobile/Docsview'),
        meta: {
            title: 'Mobile SDK 文档',
        },
    },
    {
        path: '/mobileSDK/apiview',
        name: 'MobileSDKApiView',
        component: () => import('@/views/sdk/mobile/Apiview'),
        meta: {
            title: 'Mobile API 文档',
        },
    },
    {
        path: '/mobileSDK/download',
        name: 'MobileSDKDownload',
        component: () => import('@/views/sdk/mobile/Download'),
        meta: {
            title: 'Mobile SDK 下载',
        },
    },
    {
        path: '/payloadSDK',
        name: 'PayloadSDK',
        component: () => import('@/views/sdk/payload/Overview'),
        meta: {
            title: 'Payload SDK',
        },
    },
    {
        path: '/payloadSDK/docsview',
        name: 'PayloadSDKDocView',
        component: () => import('@/views/sdk/payload/Docsview'),
        meta: {
            title: 'Payload SDK 文档',
        },
    },
    {
        path: '/payloadSDK/apiview',
        name: 'PayloadSDKApiView',
        component: () => import('@/views/sdk/payload/Apiview'),
        meta: {
            title: 'Payload API 文档',
        },
    },
    {
        path: '/payloadSDK/download',
        name: 'PayloadSDKDownload',
        component: () => import('@/views/sdk/payload/Download'),
        meta: {
            title: 'Payload SDK 下载',
        },
    },
    {
        path: '/cloudAPI',
        name: 'CloudAPI',
        component: () => import('@/views/sdk/cloud/Overview'),
        meta: {
            title: 'Cloud API',
        },
    },
    {
        path: '/cloudAPI/docsview',
        name: 'CloudAPIDocView',
        component: () => import('@/views/sdk/cloud/Docsview'),
        meta: {
            title: 'Cloud API 文档',
        },
    },
    {
        path: '/cloudAPI/download',
        name: 'CloudAPIDownload',
        component: () => import('@/views/sdk/cloud/Download'),
        meta: {
            title: 'Cloud API 下载',
        },
    },
    {
        path: '/question',
        name: 'Question',
        component: () => import('@/views/sdk/cloud/Question'),
        meta: {
            title: '用户反馈',
        },
    },
    // 贴吧页面
    {
        path: '/forumList/:type',
        name: 'ForumList',
        component: () => import('@/views/forum/ForumList'),
        meta: {
            title: '查看',
        },
    },
    {
        path: '/userbar',
        name: 'Userbar',
        component: () => import('@/views/forum/Userbar'),
        meta: {
            title: '我的贴吧',
        },
    },
    {
        path: '/zskGroup',
        name: 'ZskGroup',
        component: () => import('@/views/forum/ZskGroup'),
        meta: {
            title: '知识库分组',
        },
    },
    {
        path: '/zskView',
        name: 'ZskView',
        component: () => import('@/views/forum/ZskView'),
        meta: {
            title: '文章详情',
        },
    },
    {
        path: '/tbGroup',
        name: 'TbGroup',
        component: () => import('@/views/forum/TbGroup'),
        meta: {
            title: '主题帖',
        },
    },
    {
        path: '/tbView',
        name: 'TbView',
        component: () => import('@/views/forum/TbView'),
        meta: {
            title: '帖子详情',
        },
    },
    {
        path: '/tbNew',
        name: 'TbNew',
        component: () => import('@/views/forum/TbNew'),
        meta: {
            requiresAuth: true,
            title: '发帖',
        },
    },
    {
        path: '/search',
        name: 'Search',
        component: () => import('@/views/search/Search'),
        meta: {
            title: '搜索',
        },
    },
	// 身份认证页面
	{
	    path: '/dev-auth/index',
		name: 'DevauthIndex',
	    component: () => import('@/views/dev-auth/Index'),
		meta: {
		    title: '身份认证开始'
		}
	},
	{
	    path: '/dev-auth/steps',
		name: 'DevAuthSteps',
	    component: () => import('@/views/dev-auth/Steps'),
		meta: {
		    title: '身份认证步骤'
		}
	},
	{
	    path: '/dev-auth/success',
		name: 'DevAuthStepsSuccess',
	    component: () => import('@/views/dev-auth/StepsSuccess'),
		meta: {
		    title: '身份认证成功'
		}
	},
	//我的应用
	{
	    path: '/my-app/steps',
		name: 'MyAppSteps',
	    component: () => import('@/views/my-app/Steps'),
		meta: {
		    title: '创建应用'
		}
	},
	{
	    path: '/my-app/updsteps',
		name: 'MyAppUpdateSteps',
	    component: () => import('@/views/my-app/UpdateSteps'),
		meta: {
		    title: '编辑应用'
		}
	},
	{
	    path: '/my-app/index',
		name: 'MyAppIndex',
	    component: () => import('@/views/my-app/Index'),
		meta: {
		    title: '我的应用'
		}
	},
	{
	    path: '/my-app/details',
		name: 'MyAppDetails',
	    component: () => import('@/views/my-app/Details'),
		meta: {
		    title: '应用详情'
		}
	},
	// 我的活动
	{
	    path: '/my-act/myposts',
		name: 'MyActPosts',
	    component: () => import('@/views/my-act/MyPosts'),
		meta: {
		    title: '我的活动'
		}
	},
	// 政策法规
	{
	    path: '/policy/index',
		name: 'PolicyIndex',
	    component: () => import('@/views/policy/Index'),
		meta: {
		    title: '政策法规'
		}
	},
    {
        path: '/dictData',
        name: 'DictData',
        component: () => import('@/views/system/dictData'),
        meta: {
            title: '字典管理',
            requiresAuth: true,
            roles: ['ADMIN','PLATFORM']
        },
    },
	{
	    path: '/admin/feedbackdetail',
	    name: 'AdminFeedbackDetail',
	    component: () => import('@/views/admin/FeedbackDetail'),
	    meta: {
	        title: '用户反馈详情',
	        requiresAuth: true,
	        roles: ['ADMIN','PLATFORM']
	    }
	},
    {
        path: '/admin/zsk/LibraryGroupList',
        name: 'AdminLibraryGroupList',
        component: () => import('@/views/admin/zsk/LibraryGroupList'),
        meta: {
            title: '知识库详情',
            requiresAuth: true,
            roles: [ 'ADMIN', 'PLATFORM']
        },
    },
    {
        path: '/admin/zsk/LibraryArticleCreate',
        name: 'AdminLibraryArticleCreate',
        component: () => import('@/views/admin/zsk/LibraryArticleCreate'),
        meta: {
            title: '新建文档',
            requiresAuth: true,
            roles: [ 'ADMIN', 'PLATFORM']
        },
    },
	{
	    path: '/admin/userDetail',
	    name: 'AdminUserDetail',
	    component: () => import('@/views/admin/UserDetail'),
	    meta: {
	        title: '用户详情',
	        requiresAuth: true,
	        roles: ['ADMIN']
	    }
	},
    {
	    path: '/admin/userInfo',
	    name: 'AdminUserInfo',
	    component: () => import('@/views/admin/UserDetail'),
	    meta: {
	        title: '用户详情',
	        requiresAuth: true,
	        roles: ['ADMIN']
	    }
	},
    {
	    path: '/app/active',
	    name: 'AppActive',
	    component: () => import('@/views/Active'),
	    meta: {
	        title: '应用激活'
	    }
	},
    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401'),
        hidden: true
    }
]

export const asyncRoutes = [
     // 后台管理页面
     {
        path: '/admin/tb/theme',
        name: 'AdminTbTheme',
        component: () => import('@/views/admin/tb/Theme'),
        meta: {
            title: '贴吧主题管理',
            requiresAuth: true,
            roles: [ 'ADMIN', 'TIEBAR', 'PLATFORM']
        },
    },
    {
        path: '/admin/tb/list',
        name: 'AdminTbList',
        component: () => import('@/views/admin/tb/List'),
        meta: {
            title: '帖子管理',
            requiresAuth: true,
            roles: [ 'ADMIN', 'TIEBAR', 'PLATFORM']
        },
    },
    {
        path: '/admin/tb/user',
        name: 'AdminTbUser',
        component: () => import('@/views/admin/tb/User'),
        meta: {
            title: '贴吧用户管理',
            requiresAuth: true,
            roles: [ 'ADMIN', 'TIEBAR', 'PLATFORM']
        },
    },
    {
        path: '/admin/zsk/LibraryList',
        name: 'AdminLibraryList',
        component: () => import('@/views/admin/zsk/LibraryList'),
        meta: {
            title: '知识库管理',
            requiresAuth: true,
            roles: [ 'ADMIN', 'PLATFORM']
        },
    },
    {
        path: '/admin/feedback',
        name: 'AdminFeedback',
        component: () => import('@/views/admin/Feedback'),
        meta: {
            title: '用户反馈',
            requiresAuth: true,
            roles: ['ADMIN','PLATFORM']
        },
    },
    {
        path: '/admin/user',
        name: 'AdminUser',
        component: () => import('@/views/admin/User'),
        meta: {
            title: '用户管理',
            requiresAuth: true,
            roles: ['ADMIN','PLATFORM']
        },
    },
    {
        path: '/dict',
        name: 'Dict',
        component: () => import('@/views/system/dict'),
        meta: {
            title: '字典管理',
            requiresAuth: true,
            roles: ['ADMIN','PLATFORM']
        },
    },
    

    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true }
    
]

const createRouter = () => new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })
  
  const router = createRouter()
  
  // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
  export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
  }
  

export default router
