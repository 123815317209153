import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {

  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title);

  const hasToken = getToken()
  if(hasToken){
    // 在setRoutes方法中，添加的路由同步添加至vuex中
		let addRoutes = store.state.permission.addRoutes
    // 用户已登录，但不存在动态路由，说明页面存在刷新
		if(addRoutes.length===0){
      // get user info
      // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
      const { roles } = await store.dispatch('user/getInfo')
      // generate accessible routes map based on roles
      // const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
      const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      // hack method to ensure that addRoutes is complete
      // set the replace: true, so the navigation will not leave a history record
      next({ ...to, replace: true })
		}
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
        // determine whether the user has logged in
      
      if (hasToken) {
          if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next({ path: '/' })
            NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
          } else {
            // determine whether the user has obtained his permission roles through getInfo
            const hasRoles = store.getters.roles && store.getters.roles.length > 0
            if (hasRoles) {
              next()
            } else {
              try {
                // get user info
                // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
                const { roles } = await store.dispatch('user/getInfo')
                // generate accessible routes map based on roles
                // const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
                const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
      
                // dynamically add accessible routes
                router.addRoutes(accessRoutes)
                // hack method to ensure that addRoutes is complete
                // set the replace: true, so the navigation will not leave a history record
                next({ ...to, replace: true })
              } catch (error) {
                // remove token and go to login page to re-login
                await store.dispatch('user/resetToken')
                Message.error(error || 'Has Error')
                next(`/login?redirect=${to.path}`)
                NProgress.done()
              }
              }
            }
        } else {
          /* has no token*/
      
          if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            next()
          } else {
            // other pages that do not have permission to access are redirected to the login page.
            next(`/login?redirect=${to.path}`)
            NProgress.done()
          }
        }
  }else{
      next()
      NProgress.done()
  }
  window.scrollTo(0, 0)
});


router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
